<template>
  <div>
    <v-row dense>
      <v-col cols="3">
        <v-btn
          block
          :color="type == 'borrow' ? 'primary' : 'default'"
          @cick="changeType('borrow')"
        >
          Mượn
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          block
          :color="type == 'return' ? 'primary' : 'default'"
          @cick="changeType('return')"
        >
          Trả
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          block
          :color="type == 'return' ? 'primary' : 'default'"
          @cick="changeType('return')"
        >
          Kiểm tra
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          block
          :color="type == 'return' ? 'primary' : 'default'"
          @cick="changeType('return')"
        >
          Gán giá
        </v-btn>
      </v-col>
    </v-row>

    <BorrowAction v-if="type == 'borrow'" class="mt-5" />

    <!-- <BorrowReturnHistory class="mt-5" /> -->
  </div>
</template>

<script>
export default {
  name: "BorrowReturn",
  components: {
    BorrowAction: () => import("@/components/pos_goods/BorrowAction"),
    /* BorrowReturnHistory: () =>
      import("@/components/pos_goods/BorrowReturnHistory"), */
  },
  data: () => ({
    type: "borrow",
  }),
  methods: {
    changeType(type) {
      this.type = type;
    },
  },
};
</script>
